<template>
  <div>
    <BaseLayoutTitle :prefix="$tc('document.name', 1)" title="OCR">
    </BaseLayoutTitle>
    <vue-dropzone
      v-if="!url"
      id="dropzone"
      ref="vueDropzone"
      :options="options"
      :use-custom-slot="true"
      @vdropzone-file-added="readFile"
    >
      <v-icon large color="green accent-4">mdi-cloud-upload-outline</v-icon>
      <h3>{{ $t('document.clickHere') }}</h3>
      <div class="subtitle">
        {{ $t('document.orSelect') }}
      </div>
    </vue-dropzone>
    <v-dialog fullscreen :value="file">
      <v-snackbar
        v-model="snackbar"
        absolute
        text
        top
        :color="snackbarColor"
        :timeout="timeout"
      >
        {{ message }}

        <template v-slot:action="{ attrs }">
          <v-btn icon v-bind="attrs" @click="snackbar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <v-card flat tile>
        <v-alert
          v-if="file"
          tile
          icon="mdi-file-check-outline"
          color="grey"
          prominent
          text
        >
          <v-row align="center">
            <v-col class="grow">
              <div>{{ file.name }}</div></v-col
            ><v-col class="shrink">
              <v-btn
                class="text-capitalize"
                color="primary"
                depressed
                :loading="isLoading"
                @click="onSubmit"
                ><v-icon left>mdi-upload</v-icon
                >{{ $t('actions.import') }}</v-btn
              >
            </v-col>

            <v-col class="shrink">
              <v-btn
                class="text-capitalize"
                color="error"
                outlined
                @click="resetData"
                ><v-icon left>mdi-close</v-icon>{{ $t('actions.clear') }}</v-btn
              ></v-col
            >
          </v-row>
        </v-alert>
        <div class="iframe-container">
          <iframe v-if="url" :src="url">
            This browser does not support PDFs. Please download the PDF to view
            it: Download PDF
          </iframe>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import vueDropzone from 'vue2-dropzone'
import ApiService from '@/services/ApiService.js'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
export default {
  name: 'PdfReader',
  components: {
    vueDropzone
  },
  props: {
    resourceId: {
      type: String,
      required: true
    },
    module: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      file: null,
      url: '',
      isLoading: false,
      snackbar: false,
      message: this.$t('alerts.documentRead'),
      snackbarColor: 'success',
      timeout: 2000,
      response: null,
      order: null,
      options: {
        url: 'autoProcessQueueisFalse',
        autoProcessQueue: false,
        addRemoveLinks: true,
        createImageThumbnails: false,
        acceptedFiles: 'application/pdf'
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      this.order = await this.fetchPurchaseOrder()
    },
    async fetchPurchaseOrder() {
      return await this.$store.dispatch('purchaseOrder/fetch', this.resourceId)
    },
    async onSubmit() {
      this.isLoading = true
      await this.updateOrder()
      await new Promise(r => setTimeout(r, 1200))
      this.isLoading = false
      this.resetData()
      this.$router.replace({
        name: `${this.module}-show`,
        params: { orderId: this.resourceId }
      })
    },

    async readFile(file) {
      this.isLoading = true
      this.file = file
      const reader = new FileReader()
      reader.onloadend = async () => {
        this.url = reader.result
        this.readPdf()
        this.isLoading = false
      }
      this.$refs.vueDropzone.processQueue()
      reader.readAsDataURL(file)
    },

    createFormData(file) {
      let formData = new FormData()
      formData.set('client', 'forestchain')
      formData.set('event', 'uploadDocument')
      formData.append('file', file)
      return formData
    },
    async readPdf() {
      this.isLoading = true
      const formData = this.createFormData(this.file)
      try {
        const { data } = await ApiService.readPdf(formData)
        this.response = data.data
        this.message = this.$('alerts.documentRead')
        this.snackbar = true
      } catch (error) {
        if (!error.response) return
        this.snackbar = true
        this.message = `Error: ${error.response.status}-${
          error.response.statusText
        }. ${this.$t('alerts.tryAgain')}`
        this.snackbarColor = 'error'
        this.isLoading = false
        throw Error
      }
    },
    async updateOrder() {
      const action =
        this.module === 'purchase-order' ? 'purchaseOrder' : 'saleOrder'
      const {
        attributes: { customAttributes, defaults }
      } = this.response
      Object.assign(this.order.attributes.customAttributes, customAttributes)
      this.order.attributes.customAttributes.items = defaults.items
      await this.$store.dispatch(`${action}/update`, this.order)
    },
    resetData() {
      this.file = null
      this.url = null
    }
  }
}
</script>

<style scoped>
.iframe-container {
  position: relative;
  width: 100%;
  padding-bottom: 50%;
  background: lightgrey;
}

.iframe-container iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>
